<template>
  <section class="something-great">
    <div class="container">
      <div class="d-table">
        <div class="t-cell">
          <h2>Accomplissons votre projet</h2>
        </div>
      </div>
    </div>
    <div class="wave-wrapper">
      <div class="wave"></div>
    </div>
  </section>
</template>

<script>
  export default {
    mounted() {
      this.mymethod()
    },
    methods:{
      mymethod:function(){
        $(document).ready(function(){
          //Full Height section
          var windowHeight = $( window ).height();
          $(".something-great").css("height",windowHeight);
          $( window ).resize(function() {
            var windowHeight = $( window ).height();
            $(".something-great").css("height",windowHeight);
          });

          //Block scroll
          var done = false;
          $(window).scroll(function() {
            var scroll = $(window).scrollTop() + $( window ).height();
            if($('#anchor-2').length){
              var myElementPos = $('#anchor-2').offset().top + $('#anchor-2').outerHeight();
              if(scroll <= myElementPos){
                done = false;
              }
            }
            var childPos = $(".something-great h2").offset();
            var parentPos = $(".something-great").offset();
            var childOffset = {
                top: childPos.top - parentPos.top,
                left: childPos.left - parentPos.left
            }
            if(scroll >= myElementPos+(childOffset.top-50)){
              if(done == false){
                $('html, body').animate({
                  scrollTop: $(".something-great").offset().top+1
                }, 800, function(){
                  $(this).clearQueue().stop();
                  done = true;
                });
              }
            }
          });
        });
      }
    }
  };
</script>
<style lang="scss" scoped>
  .something-great{
    position: relative;
    overflow:hidden;
    .container{
      width:100%;
      height:100%;
    }
    h2{
      font-size:em(80);
      font-weight:$fw-xbold;
      color:$colorful;
      text-align: center;
      margin:0;
      z-index:10;
      position: relative;
      mix-blend-mode: difference;
    }
    @-webkit-keyframes wave {
      0% {
          margin-left: 0;
      }
      100% {
          margin-left: rem(-3200px);
      }
    }
    @keyframes wave {
      0% {
          margin-left: 0;
      }
      100% {
          margin-left: rem(-3200px);
      }
    }
    .wave-wrapper{
      transform: translate3d(0, 0, 0) !important;
      position:absolute;
      bottom:0;
      left:0;
      width:100%;
      height: 20.625rem;
      z-index:9;
      transition-duration: 0.55s;
      transition:all .75s ease-out;
    }
    .wave{
      background:url($imgPath+"/backgrounds/wave.svg") repeat-x;
      background-position: bottom center;
      width: 400rem;
      height: 20.625rem;
      -webkit-animation: wave 7s cubic-bezier(0.44, 0.66, 0.67, 0.37) infinite;
      animation: wave 7s cubic-bezier(0.44, 0.66, 0.67, 0.37) infinite;
      -webkit-transform: translate3d(0, 5px, 0);
      transform: translate3d(0, 5px, 0)
    }
  }
</style>  