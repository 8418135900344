// AboutComponent.vue
<template>
  <layout-blank>
    <div id="DD">
      <div class="top-header">
        <img src="https://scontent.fymq1-2.fna.fbcdn.net/v/t1.6435-9/128759880_221064812718360_1439428150443647832_n.jpg?stp=dst-jpg_s960x960&_nc_cat=101&ccb=1-7&_nc_sid=e3f864&_nc_ohc=6n9HjGphzOEAX8F53xy&_nc_ht=scontent.fymq1-2.fna&oh=00_AT8GCayF8dPlL-nY9INILXj64byS3L7-VletlQpIaY8msA&oe=62CBE4A3" />
      </div>

      <nav>
        <span id="Filter-A">Tous</span>
        <span id="Filter-RC">Rince-Crème</span>
        <span id="Filter-RQ">Rince-Quiz</span>
        <span id="Filter-SC">Sous-Crème</span>
        <span id="Filter-T">Top</span>
        <span id="Filter-M">Mortier</span>
        <span id="Filter-BR">Brounch</span>
        <span id="Filter-B">Bonus</span>
      </nav>

      <section>
        <ul>

          <li class="rince-quiz"><a target="_blank" href="https://www.youtube.com/watch?v=96yMDmELBok">Rince-Quiz</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=el-hpNz7KzI">Rince-Crème le podcast des Denis Drolet - épisode #80 (Mélanie Maynard)</a></li>
          <li class="brounch"><a target="_blank" href="https://www.youtube.com/watch?v=SnQ-L_EXG0g">Brounch Bricounche.doc (Poème de Claude Gauvreau)</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=OcjZf6uvq-I">C'est une question de Mortiers #11</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=w80JinLkz3c">Rince-Crème le podcast des Denis Drolet - épisode #79 (Jacques L'heureux)</a></li>
          <li class="brounch"><a target="_blank" href="https://www.youtube.com/watch?v=d0xrfGQTuJg">Brounch Bricounche.doc Ep#6</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=3-aUkHVg74c">Rince-Crème le podcast des Denis Drolet - épisode #78 (Alex Lévesque)</a></li>
          <li class="bonus"><a target="_blank" href="https://youtu.be/GFVTsDkVMa8">Silver & Coloïdstartz (Partie 2)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=4Se6j2bQjKo">Rince-Crème le podcast des Denis Drolet - épisode #77 (Stéphane Fallu)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=Bbv2j76a39w">Silver & Coloidstartz (Partie 1)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=idlYuxeTpsQ">Rince-Crème le podcast des Denis Drolet - épisode #76 (LeLouis Courchesne)</a></li>
          <li class="brounch"><a target="_blank" href="https://www.youtube.com/watch?v=K3n2vQwzO40">Brounch Bricounche.doc Ep#5</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=t73Cwekb2Ro">Rince-Crème le podcast des Denis Drolet - épisode #75 (Guillaume Pineault)</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=lKvVuGvRm3o">C'est une question de Mortiers #10</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=poK-0yDhvos">Rince-Crème le podcast des Denis Drolet - épisode #74 (Jean-Sébastien Girard)</a></li>
          <li class="brounch"><a target="_blank" href="https://www.youtube.com/watch?v=5ONn7OhKE9w">Brounch Bricounche.doc Ep#4</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=Vjc-cqYZ5Bs">Rince-Crème le podcast des Denis Drolet - épisode #73 (Alexandre Forest)</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=39TxPybhZ14">C'est une question de Mortiers #9</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=yXRmxaURmJk">Rince-Crème le podcast des Denis Drolet - épisode #72 (Richardson Zéphir)</a></li>
          <li class="brounch"><a target="_blank" href="https://www.youtube.com/watch?v=qZPpxxKGOdw">Brounch Bricounche.doc Ep#3</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=VdinWrOWZLA">Remerciements et Avertissements</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=AqydyBsyEDk">Rince-Crème le podcast des Denis Drolet - épisode #71 (Simon Delisle Version Douce)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=m4NBXmAAj1M">Rince-Crème le podcast des Denis Drolet - épisode #71 (Simon Delisle)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=oJgJ67SLuuA">Dracula</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=azVyHOQYQYQ">Rince-Crème le podcast des Denis Drolet - épisode #70 (Stéphane Rousseau)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=_YL-MPFzgqI">Les tops de Barbu : Top 10 Nintendo 64</a></li>
          <li class="brounch"><a target="_blank" href="https://www.youtube.com/watch?v=MVG2tD7gP24">Brounch Bricounche.doc Ep#2</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=PsfnW6_C228">Rince-Crème le podcast des Denis Drolet - épisode #69 (Julien Bernatchez)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=womjGkhp2Oo">Merci Patreons</a></li>
          <li class="sous-creme"><a target="_blank" href="https://www.youtube.com/watch?v=XNFCUtBP4bQ&feature=youtu.be">Sous-Crème - épisode #4</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=FZrZe_Ovxm8">Rince-Crème le podcast des Denis Drolet - épisode #68 (Mona De Grenoble)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=zaerGS0xHfI">Les tops de Barbu : Super Nintendo</a></li>
          <li class="brounch"><a target="_blank" href="https://www.youtube.com/watch?v=pniTUrD5Vns">Brounch Bricounche.doc Ep#1</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=QAe_JxiHKjY">Rince-Crème le podcast des Denis Drolet - épisode #67 (François Chenier)</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=bXE_W5RITRk">C'est une question de Mortiers #8</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=tYxuHHc33jU">Rince-Crème le podcast des Denis Drolet - épisode #66 (Julianne Côté)</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=sla9TpKAUr8">C'est une question de Mortiers #7</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=oOLZ0XY7VPM">Rince-Crème le podcast des Denis Drolet - épisode #65 (Thèmes des Patreon #4) ....Les batteries.</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=QPCiI1ZGx-I">C'est une question de Mortiers #6</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=jrrepWz_Sxo">Rince-Crème le podcast des Denis Drolet - épisode #64 (Philippe-Audrey Larrue St-Jacques)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=q8n8AKfyXTk">Les tops de Barbu : Comédies</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=ep6fJz6oHUI">Rince-Crème le podcast des Denis Drolet - épisode #63 (Maude Landry)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=V6OZry9jH8w">AQUARIUM</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=nNm2pD7hL4o">Rince-Crème le podcast des Denis Drolet - épisode #62 (Marc-André Grondin)</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=JU7Z4s2ok0Y">C'est une question de Mortiers #5</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=LaCZFcWKPqw">Rince-Crème le podcast des Denis Drolet - épisode #61 (Martin Petit)</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=YPTqnykjLFY">C'est une question de Mortiers #4</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=KNER7cMVKUA">Rince-Crème le podcast des Denis Drolet - épisode #60 (Marylène Gendron)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=1ApyEezMQfk">Les tops de Barbu : XBOX 360</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=SSoFZ-MDMM8">C'est une question de Mortiers #3</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=Wv2ck58w2jY">Rince-Crème le podcast des Denis Drolet - épisode #59 (Félix Turcotte)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=qloO8Ymj1Gg">La crème des lieux : Le Cimetière</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=nZCw2S_EZ48">Rince-Crème le podcast des Denis Drolet - épisode #58 (Simon Gouache)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=yVR4cwXHscs">Jocelyn : Adieu 2021 !!!</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=guGoxqInMpY">Rince-Crème le podcast des Denis Drolet - épisode #57 (Daniel Laflamme)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=OBf-AAB0iKg">Les tops de Barbu : Songwriters</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=6ZZXqIpv8o8">C'est une question de Mortiers #2</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=ZDZjaTEjUDs">Rince-Crème le podcast des Denis Drolet - épisode #56 (Le spécial de Noël)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=xcqDLb3-uGQ">Les tops de Barbu : Top 10 Jeux NES</a></li>
          <li class="mortier"><a target="_blank" href="https://www.youtube.com/watch?v=wQNhHvh2pjU">C'est une question de Mortiers #1</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=YgRBJt1GDoA">Rince-Crème le podcast des Denis Drolet - épisode #55 (Patrick Groulx) Live from Drummondville</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=fkWbzrto5Zs">Rince-Crème le podcast des Denis Drolet - épisode #54 (SPÉCIAL 1 AN)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=pJQaV31tHjY">Les tops de Barbu : David Bowie</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=yWz96I-4fvA">LA CRÈME DES LIEUX 2 (Le parc de l'église)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=vi70EazkfuM">Rince-Crème le podcast des Denis Drolet - épisode #53 (Véronique Cloutier)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=kQexgRBFsoE">Les tops de Barbu : Pink Floyd</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=ghdyMwE0H7U">Rince-Crème le podcast des Denis Drolet - épisode #52 (Stéphanie Vandelac)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=7KvW6CkZY0k">Rince-Crème le podcast des Denis Drolet - épisode #51 (Sarah-Jeanne Labrosse)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=DSWtwm7Cs-I">La Crème des Lieux. (Mes Amies, Ampoules de Peter-Pan)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=t78wcdffDMA">Rince-Crème le podcast des Denis Drolet - épisode #50 (Koriass)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=RGU1GOeqF94">Les tops de Barbu : Les Bands que j'aime et que j'aime Moins</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=5zIfedIHWW0">Rince-Crème le podcast des Denis Drolet - épisode #49 (David Beaucage)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=w8O33MZz52U">Rince-Crème le podcast des Denis Drolet - épisode #48 (Pascal Cameron)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=DpkaqzQAD0Q">Les tops de Barbu : The Beatles</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=hLLpA119NqI">Les écoles de nos Artistes</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=mX3RcE-9Vlw">Rince-Crème le podcast des Denis Drolet - épisode #47 (Michelle Desrochers)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=eXh4bCcYI7M">Les Bons Accords</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=ANtlNWF5kro">Rince-Crème le podcast des Denis Drolet - épisode #46 (Thèmes des Patreon #3)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=uLLjMKEq3bM">Rince-Crème le podcast des Denis Drolet - épisode #45 (Jean-Michel Martel)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=7EUn26u5mWE">Rince-Crème le podcast des Denis Drolet - épisode #44 (Kim Rusk)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=48Or-SeFobM">Les tops de Barbu : Tom Waits</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=xZRZuq-SnJA">Rince-Crème le podcast des Denis Drolet - épisode #43 (Jean-Thomas Jobin)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=MIBsL3iw7A4">Dans ma tête : Le Commandant Cobra</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=qE5nNiMh88M">Rince-Crème le podcast des Denis Drolet - épisode #42 (Kim Lévesque)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=qM05MUPufcw">Rince-Crème le podcast des Denis Drolet - épisode #41 (Guillaume Wagner)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=D34oZBPiV4Y">Les tops de Barbu : Metallica</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=QWccgqM9LWk">Rince-Crème le podcast des Denis Drolet - épisode #40 (Guy A Lepage)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=hipQhIK2co8">Les tops de Barbu : Suggestions d'albums à écouter ou pas</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=zENpoReQAz4">Le plus beau des Cadeaux</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=_okFDVXvRnQ">Rince-Crème le podcast des Denis Drolet - épisode #39 (Jean-François Chagnon)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=T7IO1wQyelU">Rince-Crème le podcast des Denis Drolet - épisode #38 (Silvi Tourigny)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=CQWyGU2lcxI">Les tops de Barbu : Radiohead</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=8huEk2_F_2o">Rince-Crème le podcast des Denis Drolet - épisode #37 (Les Pic-Bois)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=r200MVlGidU">Rince-Crème le podcast des Denis Drolet - épisode #36 (Julien Corriveau)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=aaGkHp_XSqE">Rince-Crème le podcast des Denis Drolet - épisode #35 (Alex A) live au ComediHa!-Fest</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=VouywCoiXLc">Rince-Crème le podcast des Denis Drolet - épisode #34 (Korine Côté) live au ComediHa!-Fest</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=zdQf4yQCLgE">Les Doublages de Silver et Coloîdstartz #1 : MASK</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=oMvXj-iuGFY">Rince-Crème le podcast des Denis Drolet - épisode #33 (Klô Pelgag)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=2rQO0KXBUYc">Rince-Crème le podcast des Denis Drolet - (Martin Dubé)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=eWF-cDIrBbA">Rince-Crème le podcast des Denis Drolet - épsiode #32 (Luc Boucher)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=IvtqPduLOmg">Rince-Crème le podcast des Denis Drolet - épisode #31 (Toutankhidou)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=F5jrmD3WvzY">Rince-Crème le podcast des Denis Drolet - épisode #30 (Marie-Lyne Joncas)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=Wz7PRRecdRA">Rince-Crème le podcast des Denis Drolet - Thèmes Bonus Vol #2</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=fTx0bRjllt4">Rince-Crème le podcast des Denis Drolet - épisode #29 (Jerr Allain)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=qaLa85TezXk">Rince-Crème le podcast des Denis Drolet - épisode #28 (Sonia Cordeau)</a></li>
          <li class="top"><a target="_blank" href="https://www.youtube.com/watch?v=VJIEx8say3A">Les tops de Barbu : Plume Latraverse</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=Jjp_DZ8ydkM">Rince-Crème le podcast des Denis Drolet - épisode #27 (Mariana Mazza)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=VW_p3PlSvg8">Le Making of de Rince-Crème</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=GdTfnV2MG3c">Rince-Crème le podcast des Denis Drolet - épisode #26 (Thèmes des Patreon #2)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=EvzjLV88Y2o">Rince-Crème le podcast des Denis Drolet - épisode #25 (Alexandre Barrette)</a></li>
          <li class="sous-creme"><a target="_blank" href="https://www.youtube.com/watch?v=722ShvAi8io">Sous-Crème - épisode #3</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=75o7CULpx1E">Rince-Crème le podcast des Denis Drolet - épisode #24 (François Boulianne)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=xDUXuEtXKGM">Rince-Crème le podcast des Denis Drolet - épisode #23 (Adib Alkhalidey)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=HuP5MU9cHo4">Rince-Crème le podcast des Denis Drolet - épisode #22 (Frédéric Pierre)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=7SjmRzOEmjM">Mise au point sur le lit de Sébas</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=a3YoCVfVR6g">Rince-Crème le podcast des Denis Drolet - épisode #21 (Sam Breton)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=oqySwzx4bww">Rince-Crème le podcast des Denis Drolet - épisode #20 (Roxane Bruneau)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=flvuAGI-ZtQ">Rince-Crème le podcast des Denis Drolet - épisode #19 (Mike Ward)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=FwdX8aZxJZI">Rince-Crème le podcast des Denis Drolet - épisode #18 (Thèmes des Patreon #1)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=27OMyAinkYI">Rince-Crème le podcast des Denis Drolet - épisode #17 (Arnaud Soly)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=7KU-7LQGkR0">Jocelyn et le printemps</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=5cJf4RJrgp4">Rince-Crème le podcast des Denis Drolet - épisode #16 (Jean-François Breau)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=5dOd4Uipo5Q">Rince-Crème le podcast des Denis Drolet - épisode #15 (Phil Roy)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=xrJa9D3LUEI">Des Lunettes</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=BQFiUvYq8uQ">Rince-Crème le podcast des Denis Drolet - épisode #14 (Rosalie Vaillancourt)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=0_nnSGpuH5M">Rince-Crème le podcast des Denis Drolet - épisode #13 (Claude Crest)</a></li>
          <li class="sous-creme"><a target="_blank" href="https://www.youtube.com/watch?v=T430BcGIY80">Sous-Crème - épisode #2</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=Ni0WeIPyDS0">Rince-Crème le podcast des Denis Drolet - épisode #12 (Sébastien Diaz)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=Lrdomtj31Ck">Rince-Crème le podcast des Denis Drolet - épisode #11 (Pierre Hébert)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=wjZEnNNCAgk">Rince-Crème le podcast des Denis Drolet - épisode #10 (Daniel Grenier)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=6dM_HKKPf50">Rince-Crème le podcast des Denis Drolet - Thèmes Bonus Vol #1</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=Er_Iuv3O6K4">Rince-Crème le podcast des Denis Drolet - épisode #9 (Thomas Levac)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=hGNOf_VTF3c">La Guerre</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=ACK2Vvamrfg">Rince-Crème le podcast des Denis Drolet - épisode #8 (Christine Morency)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=VuoA4tRZSqk">Pub - Le bon poulet Sintuber</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=u8186fvUFWI">Rince-Crème le podcast des Denis Drolet - épisode #7 (Pierre-Yves Roy Desmarais)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=eAX3yLBXIAk">Salut Pascal ! épisode #1</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=s1SEuFsKT04">Rince-Crème le podcast des Denis Drolet - épisode #6 (Pierre-François Legendre)</a></li>
          <li class="sous-creme"><a target="_blank" href="https://www.youtube.com/watch?v=XtLJI-1eqxU">Sous-Crème - épisode #1</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=ie0xYzoYqp0">Rince-Crème le podcast des Denis Drolet - épisode #5 (Katherine Levac)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=bf34tOpR4Q8">NOSTALGIE : Le Commissariat</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=MZbdDNyg6_c">NOSTALGIE : Les 2 premiers numéros du Show Absurde 2</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=audi8PxAgTU">Rince-Crème le podcast des Denis Drolet - épisode #4 (épisode pilote - Jean-Thomas Jobin)</a></li>
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=5Z_wtNE4Gf4">Les 3 Tabarnak - Le Podcast</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=mE3JUlMERnA">Rince-Crème le podcast des Denis Drolet - épisode #3 (Catherine Éthier)</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=XLc8H8rCtq0">Rince-Crème le podcast des Denis Drolet - épisode #2 (Jay Du Temple)</a></li>    
          <li class="bonus"><a target="_blank" href="https://www.youtube.com/watch?v=xuT67qlfYQ4">Jocelyn la marionnette – 2020</a></li>
          <li class="rince-creme"><a target="_blank" href="https://www.youtube.com/watch?v=n1pKoC0_iIc">Rince-Crème le podcast des Denis Drolet - épisode #1 (Anne-Élisabeth Bossé)</a></li>
        </ul>
      </section>
    </div>
  </layout-blank>
</template>

<script>
import LayoutBlank from "../layouts/LayoutBlank.vue";

export default {
  name: "DDComponent",
  components: {
    LayoutBlank,
  },
  mounted() {
    this.mymethod()
  },
  methods:{
    mymethod:function(){
      function filterToggle(target) {
        var hideList = document.querySelectorAll('li');
        for (var i = 0; i < hideList.length; ++i) {
          hideList[i].classList.add('hide');
        }
        var showList = document.querySelectorAll('li.'+target);
        for (var i = 0; i < showList.length; ++i) {
          showList[i].classList.remove('hide');
        }
      }
      (function() {
        document.getElementById('Filter-RC').addEventListener('click', function() {
          filterToggle('rince-creme');
        });
        document.getElementById('Filter-SC').addEventListener('click', function() {
          filterToggle('sous-creme');
        });
        document.getElementById('Filter-B').addEventListener('click', function() {
          filterToggle('bonus');
        });
        document.getElementById('Filter-T').addEventListener('click', function() {
          filterToggle('top');
        });
        document.getElementById('Filter-M').addEventListener('click', function() {
          filterToggle('mortier');
        });
        document.getElementById('Filter-A').addEventListener('click', function() {
          var showList = document.querySelectorAll('li');
          for (var i = 0; i < showList.length; ++i) {
            showList[i].classList.remove('hide');
          }
        });
      })();
    }
  }
};
</script>
<style scoped>
  html, body, .top-header { margin: 0; padding: 0; font-family: sans-serif; }
  main { background-color: #18191A !important; }
  .top-header { height: 200px; overflow:hidden; }
  .top-header img { width: 100%; margin: 0; }
  nav { padding: 20px; background-color: #DAB3C7; color: #9D6E9A; }
  nav span { margin-right: 20px; cursor: pointer; font-weight: bold; }
  nav span:hover { color: #EFE1E9; }
  section { padding: 20px; }
  section ul { padding: 0; margin: 0; }
  section ul li { list-style: none; }
  #DD { width: 800px; margin: 0 auto; background-color: #EFE1E9; }
  .hide { display: none; }
  a { text-decoration: none; padding: 4px; display: block; }
  .rince-creme a, .rince-creme a:visited { color: #9D6E9A; }
  .sous-creme a, .sous-creme a:visited  { color: #FE6D00; }
  .bonus a, .bonus a:visited  { color: green; }
  a:hover { background-color: #DAB3C7 !important; }
</style>