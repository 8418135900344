<template>
  <div id="layout">
    <header>
      <div class="close hover"></div>
      <nav class="container">
        <ul>
          <li>
            <router-link :to="{ name: 'Home' }" class="nav-link">Accueil</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'About' }" class="nav-link">À propos</router-link>
          </li>
          <li>
            <a href="#" class="disabled">Contact <small>En construction</small></a>
          </li>
        </ul>
        <div class="bottom-contact">
          <h3>Pour me jaser...</h3><br/>
          <a href="mailto:sambolduc9@gmail.com" class="mail">sambolduc9@gmail.com</a>
        </div>
      </nav>
    </header>
    <main class="LayoutDefault__main">
      <burgerMenu />
      <slot/>
    </main>
    <footer>
      <div class="container">
        <h2>Écrivez-moi</h2>
        <a href="mailto:sambolduc9@gmail.com" class="link">sambolduc9@gmail.com</a>
        <!-- <router-link :to="{ name: 'Contact' }" class="btn color-fill light-to-colorful">Par formulaire de contact</router-link> -->
      </div>
    </footer>
  </div>
</template>

<script>
  import burgerMenu from "@/components/burger-menu.vue";
  export default {
    name: 'Layout',
    components: {
      burgerMenu
    }
  }
</script>