<template>
  <div>
    <div id="loading-screen" class="">
      <div class="d-table">
        <div class="t-cell">
          <div class="loader">
            <div class="loader__bar"></div>
            <div class="loader__bar"></div>
            <div class="loader__bar"></div>
            <div class="loader__bar"></div>
            <div class="loader__bar"></div>
            <div class="loader__ball"></div>
          </div>
        </div>
      </div>
    </div>
    <div id="app">
      <transition name="router-anim">
        <loading v-if="$root.loading"></loading>
        <router-view v-else></router-view>
      </transition>
      <myCursor></myCursor>
    </div>
  </div>
</template>

<script>
  import myCursor from "@/components/cursor.vue";
  export default {
    name: 'app',
    components: {
      myCursor,
    }
  }
</script>
<style lang="scss">
  @import 'assets/scss/mains.scss';
</style>