// main.js

import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import NProgress from "nprogress";
import '@/plugins/google-analytics'

import IndexComponent from "./pages/IndexComponent.vue";
import AboutComponent from "./pages/AboutComponent.vue";
import ContactComponent from "./pages/ContactComponent.vue";
import DDComponent from "./pages/DDComponent.vue";

import "../node_modules/nprogress/nprogress.css";

import $ from "jquery";

Vue.config.productionTip = false;

Vue.use(VueRouter);

window.$ = require('jquery')
window.JQuery = require('jquery')

const routes = [
  {
    name: "Home",
    path: "/",
    meta: {title: "Développement web | sbolduc | Accueil"},
    component: IndexComponent
  },
  {
    name: "About",
    path: "/a-propos",
    meta: {title: "À propos du développement web | sbolduc"},
    component: AboutComponent
  },
  {
    name: "Contact",
    path: "/contact",
    meta: {title: "Contactez sbolduc pour votre projet web"},
    component: ContactComponent
  },
  {
    name: "DD",
    path: "/dd",
    meta: {title: "DD"},
    component: DDComponent
  }
];

//SCROLLING
var currentScroll = 0;
var win = $(window);
var winHeight = win.height();
var winWidth = win.width();
var percentage;
var decimal;

window.animScroll = function(elem){
  if(winWidth > 767){
    var element_position = elem.offset().top;
    var elementHeight = elem.outerHeight();
    var y_scroll_pos = win.scrollTop()+winHeight;

    if(y_scroll_pos > element_position && !(y_scroll_pos > element_position+elementHeight+150) ) {
      var windowBottom = win.scrollTop() + winHeight;
      var elementTop = elem.offset().top;
      if(winHeight < elementHeight){
        var difference = elementHeight-winHeight;
        currentScroll+=difference;
        percentage = (windowBottom - elementTop + difference + (elementHeight/8)) / elementHeight * 100;
      }
      else{
        percentage = (windowBottom - elementTop + (elementHeight/8)) / elementHeight * 100;
      }
      currentScroll = 100-percentage;
      decimal = percentage/100;
      if(decimal < 1){
        elem.find($(".offset")).css("top","+"+currentScroll+"px");
      }
    }
  }
}

const router = new VueRouter({ mode: "history", routes: routes });

Vue.component('loading',{ template: '<div>Loading!</div>'})

router.beforeEach((to, from, next) => {
  setTimeout(function(){
    var loadingScreen = document.getElementById("loading-screen");
    loadingScreen.classList.remove("loaded");
  }, 10);
  app.loading = true;
	next()
})

router.afterEach((to, from, next) => {
  app.loading = false;
})

router.beforeResolve((to, from, next) => {
  NProgress.start();
  app.loading = true;
  setTimeout(function(){
    next();
    var loadingScreen = document.getElementById("loading-screen");
    loadingScreen.classList.add("loaded");
    //loading.remove(1);
  }, 2000);
})

router.afterEach((to, from) => {
  app.loading = false
  NProgress.done()
})

router.afterEach(to => {
  document.title = to.meta.title;
});

new Vue({
  render: h => h(App),
  router,
  loaded: true,
}).$mount("#app");