<template>
  <div class="custom-cursor" :class="hideCursor">
    <div class="cursor custom-cursor__circle" :class="[hold,hover]" :style="circleStyle" ref="customCursorCircle"></div>
    <div class="cursor custom-cursor__dot" :style="dotStyle" :class="hover" ref="customCursorDot"></div>
  </div>
</template>

<script>
export default {
  name: "CustomCursor",
  props: {
    circleColor: String,
    circleColorHover: String,
    dotColor: String,
    dotColorHover: String,
    hoverSize: Number
  },
  data() {
    return {
      targets: ['a', 'button', '.hover'],
      scale: 1,
      x: null,
      y: null,
      circlePosX: null,
      circlePosY: null,
      dotPosX: null,
      dotPosY: null,
      circleStyle: null,
      dotStyle: null,
      hold:null,
      hover:null,
      hideCursor:null
    };
  },
  methods: {
    customCursor(e) {
      this.hideCursor = "";
      // cursor pos
      this.x = e.clientX;
      this.y = e.clientY;

      // cursor circle
      const circle = this.$refs.customCursorCircle;
      this.circlePosX = this.x - circle.clientWidth / 2;
      this.circlePosY = this.y - circle.clientWidth / 2;

      //change style when hovering on selected targets
      if (
        (this.targets.length > 0 &&
          this.targets.includes(e.target.tagName.toLowerCase())) ||
        this.targets.includes(e.target.className.toLowerCase())
      ) {
        this.hover = "hover";
      } else {
        this.hover = "";
      }

      // cursor dot
      const dot = this.$refs.customCursorDot;
      this.dotPosX = this.x - dot.clientWidth / 2;
      this.dotPosY = this.y - dot.clientHeight / 2;

      //move custom cursor
      circle.style.transform = `translate(${this.circlePosX}px,${this.circlePosY}px) scale(${this.scale})`;
      dot.style.transform = `translate(${this.dotPosX}px,${this.dotPosY}px)`;
    },
    mouseDown(e) {
      this.hold = "hold";
    },
    mouseUp(e) {
      this.hold = "";
    }
  },
  mounted() {
    window.addEventListener("mousemove", this.customCursor);
    window.addEventListener("mousedown", this.mouseDown);
    window.addEventListener("mouseup", this.mouseUp);
    document.addEventListener('mouseleave', e => {
      this.hideCursor = "hideCursor";
    });
  }
};
</script>

<style lang="scss" scoped>

.custom-cursor {
  cursor: none;
  pointer-events: none;
  &.hideCursor{
    opacity:0;
  }
  &.hovered{
    .cursor{
      filter: FlipH;
      -ms-filter: "FlipH";
      -moz-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      &.custom-cursor__dot{
        background-color:$light-color;
      }
      &.custom-cursor__circle{
        border-color:$light-color;
      }
    }
  }
}
.cursor{
  position: fixed;
  cursor: none;
  mix-blend-mode: difference;
  border-radius: 50%;
  z-index:9999;
  filter: FlipH;
  -ms-filter: "FlipH";
  &.custom-cursor__dot{
    top: 1px;
    left: 1px;
    width: 15px;
    height: 15px;
    background-color:$colorful;
    transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
    @include transition(unquote("transform 0.2s cubic-bezier(0.23, 1, 0.32, 1), background-color ease-in-out 300ms"));
  }
  &.custom-cursor__circle{
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    margin:0;
    opacity:1;
    border:1px solid $colorful;
    @include transition(unquote("transform 1s cubic-bezier(0.23, 1, 0.32, 1),margin-left 200ms ease-in-out,margin-top 200ms ease-in-out,opacity 200ms ease-in-out,width 200ms ease-in-out,height 200ms ease-in-out,background-color ease-in-out 300ms,background-color ease-in-out 300ms,border-color ease-in-out 300ms"));
  }
  &.hold{
    &.custom-cursor__circle{
      width:15px;
      height:15px;
      margin-left:7.5px;
      margin-top:7.5px;
      opacity:0;
    }
  }
  &.hover{
    mix-blend-mode: normal;
    background-color:#000;
    border-color:#FFF;
    &.custom-cursor__dot{
      background-color:#FFF;
    }
  }
}
</style>