<template>
  <layout-default>
    <div id="contact">
      <p>ContactComponent Works!!</p>
      <pre>{{ info }}</pre>
    </div>
  </layout-default>
</template>
<script>
import axios from "axios";
import LayoutDefault from "../layouts/LayoutDefault.vue";

export default {
  name: "ContactComponent",
  components: {
    LayoutDefault
  },
  data () {
    return {
      info: null
    }
  },
  beforeCreate: function () {
    // `this` points to the vm instance
  },
  mounted () {
    axios
      .get("./assets/json/articles.json")
      .then(
        response => (this.info = response)
      )
  }
}
</script>