<template>
  <section class="articles-cont">
    <div class="container small-cont offset">
      <div v-masonry item-selector=".masonry" class="masonry-container">
        <div class="masonry" v-for="data in $options.myJson">
          <box-project v-if="data.type === 'project'" :href="data.link">
            <template v-slot:title>{{data.title}}</template>
            <template v-slot:subtitle>{{data.subtitle}}</template>
            <template v-slot:img><img :src="loadImage(data.pict)"></template>
          </box-project>
          <box-article v-if="data.type === 'article'">
            <template v-slot:date>{{data.date}}</template>
            <template v-slot:content>{{ data.content }}</template>
          </box-article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  //v-if="data.type === 'project'"  
  import Vue from 'vue'
  import axios from "axios";
  import boxProject from "@/components/box-project.vue";
  import boxArticle from "@/components/box-article.vue";
  import {VueMasonryPlugin} from 'vue-masonry';
  import json from '@/assets/json/articles.json';

  export default {
    myJson: json,
    components: {
      boxProject,
      boxArticle
    },
    methods: {
      loadImage (imgName) {
        return require('@/assets/img/projects/' + imgName )
      }
    }
  };

  Vue.use(VueMasonryPlugin);

</script>