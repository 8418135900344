<template>
  <div class="menu hover">
    <div class="d-table">
      <div class="t-cell text-center">
        <div class="burger">
          <span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    mounted() {
      this.mymethod()
    },
    methods:{
      mymethod:function(){
        $(document).ready(function(){
          //Opening Menu
          $(".menu").on("click",function(){
            $(this).toggleClass("active");
            $("header").addClass("active");
          });
          $("header .close").on("click",function(){
            $("header").removeClass("active");
          });
        });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .burger{
    position: relative;
    &::before,&::after{
      content:"";
    }
    &::after{margin-bottom:0;}
    span,&::before,&::after{
      width:em(27);
      height:em(2);
      background-color:$light-color;
      display:block;
      margin:0 auto em(2);
      @include transition(all ease-in-out 200ms);
    }
  }
</style>