<template>
  <layout-default>
    <div id="home">
      <entete />
      <div class="scroll-box">
        <subTitle id="anchor-1">
          <template v-slot:title>Mes</template>
          <template v-slot:subTitle>projets</template>
          <template v-slot:subSubTitle>Voici un aperçu de mes<br/> derniers projets et articles</template>
        </subTitle>
        <articles-cont class="negative" />
      </div>
      <me-box />
      <subTitle id="anchor-2" class="no-negative">
        <template v-slot:title>Plus à propos</template>
        <template v-slot:subTitle>de moi</template>
        <template v-slot:subSubTitle>J'ai une multitude de talents qui seront utile<br/> pour l'accomplissement de votre projet venez en savoir plus.</template>
        <template v-slot:btn>
          <a href="/a-propos" class="btn color-fill colorful">À propos de moi</a>
        </template>
      </subTitle>
      <wave />
    </div>
  </layout-default>
</template>

<script>

  import LayoutDefault from '../layouts/LayoutDefault.vue';
  import entete from "@/components/entete.vue";
  import subTitle from "@/components/sub-title.vue";
  import articlesCont from "@/components/articles-cont.vue";
  import meBox from "@/components/me-box.vue";
  import wave from "@/components/wave-section.vue";

  export default {
    name: "home",
    components: {
      entete,
      subTitle,
      articlesCont,
      meBox,
      wave,
      LayoutDefault
    },
    mounted() {
      this.mymethod()
    },
    methods:{
      mymethod:function(){
        $(document).ready(function(){

          //Block scroll
          $(window).scroll(function() {

            animScroll($('.scroll-box'));

          });

          /*
          ==========================
          ==Smooth Scroll function==
          ==========================
          */
         console.log("test");
          $('a[href*="#"]:not([href="#"])').click(function() {
            if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');

              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000);

                return false;
              }
            }
          });
        })
      }
    }
  };
</script>
<style lang="scss">
  @import '../assets/scss/pages/index.scss';
</style>
