// AboutComponent.vue
<template>
  <layout-default>
    <div id="about">
      <full-title>
        <template v-slot:title>Je m'occupe de votre site web</template>
      </full-title>
      <colorful-text>
        <template v-slot:text></template>
      </colorful-text>
    </div>
  </layout-default>
</template>

<script>
import LayoutDefault from '../layouts/LayoutDefault.vue';
import fullTitle from "@/components/full-title.vue";
import colorfulText from "@/components/colorful-text.vue";

export default {
  name: 'AboutComponent',
  components: {
    LayoutDefault,
    fullTitle,
    colorfulText
  }
}
</script>